import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-1";
import ImageSection from "@components/ImageBox";
import CaseStudyFacts from "@containers/case-study/facts";
import LanguageFacts from "@containers/language-translation/facts";
import BoxSection from "@components/BoxSection/layout-two/layout-2";
import IntroAreaReverseTwo from "@components/introareareverse/layout-3";

const CaseStudyQigroup = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Case study - Qigroup"
        description="They not only hold extensive experience in technical skills but they are beauty-oriented. When doing artwork, they can anticipate how the brands should be beautifully displayed in the localized languages "
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["qigroup-header-section"]} />
        <ImageSection data={content["case-study-qigroup-image-body"]} />
        <CaseStudyFacts data={content["qigroup-fast-facts"]} />
        <IntroAreaReverseTwo layout={3} data={content["case-study-qigroup-testimonials"]} />

        <BoxSection layout={3} data={content["qigroup-intro"]} />
        {/* <IntroArea data={content["qigroup-intro"]} /> */}
        <LanguageFacts data={content["qigroup-challenge"]} />
        <IntroArea layout={2} data={content["qigroup-solutions"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudyQigroupPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "case-studies" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseStudyQigroup.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseStudyQigroup;
